import httpRequest from '../../utilities/js/httpRequest/httpRequest';
import navigationStore from '../../stores/navigation/NavigationStore';
import {CategoryTree} from '../../types/Navigation';

type NavigationResponseData = {
  children: CategoryTree[];
  currentElement: CategoryTree;
}

class NavigationService {
  private url = window.__globalConfig ? window.__globalConfig.apiUrls.navigation : '';

  getCategories(categoryId: string = ''): Promise<{data: NavigationResponseData}> {
    return httpRequest(`${this.url}?categoryId=${categoryId}`, {
      method: 'get',
    });
  }


  // BurgerNavigation
  //////////////////////
  protected loadedCategories: string[] = [];

  // better name, indicating that there is a request to me made
  loadCategories = this.getCategories;

  async loadCategoryLevel(categoryId: string, path: number[] = null) {
    const targetNode = this.findNodeByPath(path);

    if (categoryId !== undefined && targetNode.hasChildren && targetNode.subcategories.length === 0) {
      this.loadedCategories.push(categoryId);

      const response = await navigationService.loadCategories(categoryId);
      if (path && response.data.children && response.data.children.length > 0) {
        targetNode.subcategories.push(...response.data.children);
      } else {
        navigationStore.navigationTree.push(...response.data.children);
      }
    } else {
      return Promise.resolve();
    }
  }

  findNodeByPath(path: number[]): CategoryTree {
    let pointer = this.createRootCategoryNode();
    if (path) {
      path.forEach((index) => {
        pointer = pointer.subcategories[index];
      });
    }
    return pointer;
  }

  findIndexPathByCategoryId(categoryId: string, navTree: CategoryTree[]): number[]|null {
    if (!categoryId) return null;

    let ndx = navTree.length;
    while (ndx--) {
      if (navTree[ndx].id == categoryId) {
        return [ndx];
      }
      else if (navTree[ndx].subcategories.length) {
        return [ndx, ...this.findIndexPathByCategoryId(categoryId, navTree[ndx].subcategories)];
      }
    }

    return null;
  }

  createRootCategoryNode(): CategoryTree {
    return {
      name: '',
      url: '',
      id: 'root',
      icon: '',
      target: '',
      hasChildren: true,
      subcategories: navigationStore.navigationTree,
    }
  }
}

const navigationService = new NavigationService();
export {navigationService};
