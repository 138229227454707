<template>
  <component
    :is="lazyLoad ? 'lazyLoad' : 'ul'"
    :tag="lazyLoad ? 'ul' : null"
    v-if="flagData && flagData.length > 0"
    class="flags"
  >
    <li
      v-for="(flag, index) in flagData"
      :key="index"
      class="flag"
      :class="[`flag--${flag.type}`]"
      @click="clickHandler(flag, $event)"
    >
      <template
        v-if="flag.type === 'award' || flag.type === 'awardInSet'"
      >
        <image-element
          :src="[flag.data]"
          alt=""
        />
      </template>
      <template
        v-else-if="flag.type === 'boxSetLayer'"
      >
        <div class="flag__boxSetLayer">
          <info-popup
            v-if="flags.boxSetLayer"
            :show-close-icon="true"
            position="bottom-left"
          >
            <template #label>
              <div
              
                class="flag__boxSetLayer-content"
              >
                <span
                  class="flag__boxSetLayer-text"
                  v-html="$t('flags.boxSetLayer.text')"
                />
                <image-element
                  :src="[settings.flags.src.boxSetImage]"
                  alt=""
                />
              </div>
            </template>
            {{ $t('flags.boxSetLayer.info', [flags.boxSetLayer.noOfBottlesForBox]) }}
          </info-popup>
        </div>
      </template>
      <template
        v-else-if="flag.type === 'boxSetUrl'"
      >
        <image-element
          :src="[settings.flags.src.boxSetImage]"
          alt=""
        />
      </template>
      <span
        v-else-if="flag.type === 'quote'"
      >
        <span
          class="flag__quote"
          v-html="flag.data[0]"
        />
        <span
          class="flag__author"
          v-html="flag.data[1]"
        />
      </span>
      <template
        v-else-if="flag.type === 'takeAndPayBenefitText' || flag.type === 'takeAndPayBenefitAppliedItemsText'"
      >
        <span
          class="flag__takeAndBenefit"
          v-html="flag.data"
        />
      </template>
      <template
        v-else-if="flag.type === 'nachhaltigkeit'"
      >
        <a
          :href="$t('flags.nachhaltigkeit.link')"
          v-if="enableLinks"
          class="flag--link"
          v-html="flag.data"
          :target="getTarget(flag.type)"
        />
        <span
          v-else
          v-html="flag.data"
        />
      </template>
      <span
        v-else
        v-html="flag.data"
      />
    </li>
  </component>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';
  import {TranslateResult} from 'vue-i18n';
  import type Flags from '../../types/Flags';
  import type FlagList from '../../types/FlagList';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';
  import InfoPopup from '../infoPopup/infoPopup.vue';
  import ImageElement from '../imageElement/imageElement.vue';
  import diContainer from '../../utilities/js/diContainer/diContainer';
  import {ProductDetailsSettings} from '../productDetails/productDetailsSettings';

  type FlagData = {
    type: keyof Flags,
    data: string | [string, string] | TranslateResult | number
  }

  @Options({
    name: 'flags',
    components: {ImageElement, LazyLoad, InfoPopup}
  })
  export default class FlagsClass extends Vue {
    @Prop({required: true})
    flags: Flags;

    @Prop({default: true})
    lazyLoad: boolean;

    @Prop({default: 3})
    limit: number;

    @Prop({default: 3})
    awardLimit: number;

    @Prop({type: Array})
    whiteList: FlagList;

    @Prop({type: Array})
    limitIgnoringFlags: FlagList; // Flags that are not counted toward the limit

    @Prop({default: false})
    enableLinks: boolean;

    @Prop({type: Array, default: () => []})
    targetBlankFlags: string[];

    @Prop({default: false})
    withSavingsMax: boolean;

    settings: ProductDetailsSettings = diContainer.get('productDetailsSettings');
    defaultFlagOrder: FlagList = ['takeAndPayBenefitText', 'takeAndPayBenefitAppliedItemsText', 'free', 'new', 'newVintage', 'savings', 'savingsMax', 'awardInSet', 'award', 'stoerer', 'nachhaltigkeit', 'bio', 'vegan', 'quote', 'boxSetUrl'];

    get flagData(): FlagData[] {
      const flagList: FlagData[] = [];
      const flagOrder = this.whiteList || this.defaultFlagOrder;
      let flagLimit = this.limit;

      flagOrder.forEach((flagType) => {
        if (this.flags.subscription && flagType === 'subscription') {
          flagList.push({
            type: 'subscription', data: this.$t('flags.subscription')
          });
        } else if (this.flags.new && flagType === 'new') {
          flagList.push({
            type: 'new', data: this.$t('flags.new')
          });
        } else if (this.flags.newVintage && flagType === 'newVintage') {
          flagList.push({
            type: 'newVintage', data: this.$t('flags.newVintage')
          });
        } else if (this.flags.savings && flagType === 'savings') {
          if (this.withSavingsMax && parseInt(this.flags.savings) > 50) {
            flagList.push({
              type: 'savingsMax', data: this.$t('flags.savingsMax')
            })
          } else if (this.flags.savings !== '100') {
            flagList.push({
              type: 'savings', data: `${this.settings.flags.minusText}${this.flags.savings}${this.settings.flags.percentText}`
            })
          }
        } else if (this.flags.awardInSet && flagType === 'awardInSet') {
          flagList.push({
            type: 'awardInSet', data: this.settings.flags.src.awardInSetImage
          })
        } else if (this.flags.awardImageUrls && this.flags.awardImageUrls.length && flagType === 'award') {

          const awardLimit = Math.min(this.awardLimit, this.flags.awardImageUrls.length);

          for (let ndx = 0; ndx < awardLimit; ndx++) {
            flagList.push({
              type: 'award', data: this.flags.awardImageUrls[ndx]
            });
          }
        } else if (this.flags.bottleSize && flagType === 'bottleSize') {
          flagList.push({
            type: 'bottleSize', data: this.flags.bottleSize
          });
        } else if (this.flags.nonAlcoholic && flagType === 'nonAlcoholic') {
          flagList.push({
            type: 'nonAlcoholic', data: this.$t('flags.nonAlcoholic')
          });
        } else if (this.flags.nachhaltigkeit && flagType === 'nachhaltigkeit') {
          flagList.push({
            type: 'nachhaltigkeit', data: this.$t('flags.nachhaltigkeit')
          });
        } else if (this.flags.bio && flagType === 'bio') {
          flagList.push({
            type: 'bio', data: this.$t('flags.bio')
          });
        } else if (this.flags.vegan && flagType === 'vegan') {
          flagList.push({
            type: 'vegan', data: this.$t('flags.vegan')
          });
        } else if (this.flags.free && flagType === 'free') {
          flagList.push({
            type: 'free', data: this.$t('flags.free')
          });
        } else if (this.flags.quote && flagType === 'quote') {
          flagList.push({
            type: 'quote', data: [this.flags.quote.text, this.flags.quote.author]
          });
        } else if (this.flags.takeAndPayBenefitText && flagType === 'takeAndPayBenefitText') {
          flagList.push({
            type: 'takeAndPayBenefitText', data: this.flags.takeAndPayBenefitText
          });
        } else if (this.flags.takeAndPayBenefitAppliedItemsText && flagType === 'takeAndPayBenefitAppliedItemsText') {
          flagList.push({
            type: 'takeAndPayBenefitAppliedItemsText', data: this.flags.takeAndPayBenefitAppliedItemsText
          });
        } else if (this.flags.boxSetUrl && flagType === 'boxSetUrl') {
          flagList.push({
            type: 'boxSetUrl', data: this.flags.boxSetUrl
          });
        } else if (this.flags.boxSetLayer?.noOfBottlesForBox && flagType === 'boxSetLayer') {
          flagList.push({
            type: 'boxSetLayer', data: null
          });
        } else if (this.flags.stoerer && this.flags.stoerer.length && flagType === 'stoerer') {
          for (let ndx = 0; ndx < this.flags.stoerer.length; ndx++) {
            flagList.push({
              type: 'stoerer', data: this.flags.stoerer[ndx]
            });
          }
        } else if (this.flags.personalized && flagType === 'personalized') {
          flagList.push({
            type: 'personalized', data: this.$t('flags.personalized')
          });
        }
      });

      if (this.limitIgnoringFlags) {
        flagList.forEach((flag) => {
          if (this.limitIgnoringFlags.includes(flag.type)) {
            flagLimit++;
          }
        });
      }

      return flagList.slice(0, flagLimit);
    }

    getTarget(flagType) {
      if (this.targetBlankFlags.indexOf(flagType) >= 0) {
        return '_blank';
      }
      return '_self';
    }

    clickHandler(flag, event) {
      if (flag.type === 'award') {
        location.hash = '#awards';
      }

      if (flag.type === 'awardInSet') {
        location.hash = '#setContains';
      }

      if (flag.type === 'boxSetUrl') {
        event.preventDefault();
        window.location.href = flag.data;
      }
    }
  }
</script>
