<template>
  <div
    class="burger-menu__wrapper"
    v-if="showMenu"
  >
    <div
      class="burger-menu__wrapper-background"
      @click.prevent="close"
    />
    <nav
      class="burger-menu"
      :class="{
        'burger-menu--open': isOpen
      }"
      ref="burgerMenu"
      :style="menuStyle"
    >
      <span
        class="burger-menu__close-button"
        @click.prevent="close"
        role="button"
      />

      <div @click="localisationClick()">
        <slot name="i18n" />
      </div>

      <div
        class="burger-menu__loader"
        v-if="isLoading"
      />
      <div class="burger-menu__nav-wrapper">
        <burger-nav-tree
          v-if="showMenu"
          class="burger-menu__navtree"
          :categories="navigationTree"
          :headline="headline || $t('navigation.burger.menu')"
          :breadcrumb-tree="breadcrumbTree"
          :is-loading="isLoading"
          @levelChange="navTreeLevelChangeHandler"
        >
          <template
            v-for="(slot, key) in $slots"
            #[key]
          >
            <slot :name="key" />
          </template>
        </burger-nav-tree>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
  import breakpoints from '../../variables/js/breakpoints.js';
  import {navigationService} from '../../services/navigationService/NavigationService';
  import type {CategoryTree, AdditionalNavItem} from '../../types/Navigation';

  import {Vue, Options, Prop, Watch} from 'vue-property-decorator';
  import pigeon from '../../utilities/js/pigeon/pigeon.js';
  import BurgerNavTree from '../burgerNavTree/burgerNavTree.vue';

  @Options({
    name: 'burger-menu',
    components: {
      BurgerNavTree
    }
  })
  export default class BurgerMenu extends Vue {
    @Prop()
    openTriggerSelector: string;

    @Prop({default: () => []})
    additionalNav: Array<AdditionalNavItem>;

    @Prop()
    breadcrumb!: string;

    @Prop({default: 60})
    mobileBurgerTop: number;

    @Prop({default: 65})
    tabletBurgerTop: number;

    @Prop({default: true})
    loadCategories: boolean;

    @Prop({default: ''})
    headline: string;

    isOpen = false;
    showMenu = false;
    menuStyle = null;
    isLoading = false;
    navigationTree: CategoryTree[] = [];
    loadedCategories: string[] = [];
    breadcrumbTree: string[] = (this.breadcrumb) ? this.breadcrumb.split(' > ') : [];

    mounted() {
      pigeon.subscribe(`nativeAppMenu.open${this.openTriggerSelector}`, this.open.bind(this));
      pigeon.subscribe(`nativeAppMenu.close${this.openTriggerSelector}`, this.close.bind(this));
      pigeon.subscribe('campaignheader:close', this.setMenuStyle.bind(this));

      if (this.openTriggerSelector) {
        this.bindTriggers();
      }
    }

    @Watch('isOpen')
    initialize() {
      if (this.isOpen) {
        if (this.navigationTree.length === 0) {
          this.navigationTree = this.getNonCategoryNavItems();
          this.getCategoryLevel('', null);
        }
      }
    }

    navTreeLevelChangeHandler(data: {categoryId: string, path: number[], direction: 'up'|'down'}) {
      if (!this.isOpen) {
        return;
      }
      if (data.direction === 'down' && data.categoryId) {
        this.getCategoryLevel(data.categoryId, data.path);
      }
      const burgerMenu: any = this.$refs.burgerMenu;
      burgerMenu.scrollTop = 0;
    }

    getCategoryLevel(categoryId: string, path: number[] = null) {
      if (categoryId !== undefined && this.loadedCategories.indexOf(categoryId) === -1 && this.loadCategories) {
        this.loadedCategories.push(categoryId);
        this.isLoading = true;

        navigationService.getCategories(categoryId).then((response) => {
          this.isLoading = false;
          if (path && response.data.children && response.data.children.length > 0) {
            let pointer = this.navigationTree;
            path.forEach((index) => {
              pointer = pointer[index].subcategories;
            });
            pointer.push(...response.data.children);
          } else {
            this.navigationTree = [...response.data.children, ...this.navigationTree];
          }
        });
      }
    }

    getNonCategoryNavItems(): CategoryTree[] {
      return this.getAdditionalNavItems(this.additionalNav);
    }

    getAdditionalNavItems(additionalNavItems: AdditionalNavItem[]): CategoryTree[] {
      let result: CategoryTree[] = [];

      additionalNavItems.forEach((additionalNavElement) => {
        if (additionalNavElement.subcategories || additionalNavElement.url) {
          let catTreeObj: CategoryTree = {
            name: additionalNavElement.name || '',
            url: additionalNavElement.url || '',
            id: additionalNavElement.url || '',
            icon: additionalNavElement.icon || '',
            target: additionalNavElement.target || '_self',
            hasChildren: additionalNavElement.subcategories?.length > 0,
            subcategories: additionalNavElement.subcategories ?
              this.getAdditionalNavItems(additionalNavElement.subcategories) : []
          };
          result.push(catTreeObj);
        }
      });

      return result;
    }

    bindTriggers() {
      const triggers = Array.prototype.slice.call(document.querySelectorAll(this.openTriggerSelector));
      for (let i = 0; i < triggers.length; ++i) {
        triggers[i].addEventListener('click', (event) => {
          event.preventDefault();
          this.open();
        });
      }
    }

    setMenuStyle() {
      this.menuStyle = null;
      window.setTimeout(() => {
        const campaignHeader = document.querySelector('.campaign-header');
        if (campaignHeader) {
          const height = campaignHeader.getBoundingClientRect().height;
          const top = (window.innerWidth < breakpoints.tablet) ? this.mobileBurgerTop : this.tabletBurgerTop;
          this.menuStyle = {
            marginTop: height + 'px',
            height: `calc(100% - ${height + top}px)`
          };
        }
      }, 200);
    }

    open() {
      this.setMenuStyle();
      this.showMenu = true;
      pigeon.publish('pageoverlay:show', 'page-overlay--visible-transparent');
      pigeon.publish('pageoverlay:lock', 'no-scroll--burger');
      pigeon.publish('burgermenu:open', 'no-scroll--burger');
      window.setTimeout(() => {
        this.isOpen = true;
      }, 20);
    }

    close() {
      this.isOpen = false;
      pigeon.publish('pageoverlay:hide', 'page-overlay--visible-transparent');
      pigeon.publish('pageoverlay:unlock', 'no-scroll--burger');
      pigeon.publish('burgermenu:close', 'no-scroll--burger');
      window.setTimeout(() => {
        this.showMenu = false;
      }, 500);
    }

    localisationClick() {
      this.close();
      pigeon.publish('localisationSwitch:open');
    }
  }
</script>
