import JsComponent from '../jsComponent/jsComponent.js';
import pigeon from '../../utilities/js/pigeon/pigeon.js';

class UserShortcut extends JsComponent {
  constructor(mainElement, name = 'user-shortcut') {
    super(mainElement, name);
    this.createClassNames(
      '__notification-badge',
      '__notification-badge--active',
      '__notification-badge--update',
      '--has-badge'
    );

    this.type = this.mainElement.dataset.type;
    this.notificationBadge = this.mainElement.querySelector(`.${this.classNames.notificationBadge}`);

    if (this.type === 'cart') {
      this.createCartShortcut();
      this.addEvents();
      pigeon.subscribe('productaddedToCart:updateCounter', this.updateCounter.bind(this));
      pigeon.subscribe('productRemovedFromCart:updateCounter', this.updateCounter.bind(this));
    } else if (this.type === 'wishlist') {
      this.createWishlistShortcut();
      pigeon.subscribe('productaddedToWishlist:updateCounter', this.updateWishlistCounter.bind(this));
    } else {
      if (this.notificationBadge && this.notificationBadge.attributes['data-initial-count']) {
        this.setInitialBadgeCount(this.notificationBadge.attributes['data-initial-count'].value);
      }
    }
  }

  addEvents() {
    this.mainElement.addEventListener('mouseover', this.handleCartClick.bind(this));
    this.mainElement.addEventListener('mouseout', this.cancelCartLayer.bind(this));
    this.mainElement.addEventListener('touchend', this.handleCartClick.bind(this));
  }

  handleCartClick(event) {
    const isSmallLayer = document.querySelector('.add-to-cart-layer--small') !== null;
    if (isSmallLayer) {
      return true;
    }

    const isLayerOpen = document.querySelector('.add-to-cart-layer .add-to-cart-layer__header') != null;
    if (!isLayerOpen && this.notificationBadge.dataset.initialCount > 0) {
      pigeon.publish('userShortcut:openCartLayer');
      event.preventDefault();
      return false;
    }
  }

  cancelCartLayer() {
    clearTimeout(this.timeoutId);
  }

  updateCounter(data) {
    if (!data && data !== 0) {
      return;
    }
    this.notificationBadge.dataset.initialCount = data;
    this.setInitialBadgeCount();

    this.notificationBadge.classList.remove(this.classNames.notificationBadgeUpdate);
    setTimeout(() => { this.notificationBadge.classList.add(this.classNames.notificationBadgeUpdate); }, 10);
  }

  updateWishlistCounter(data) {
    if (!data) {
      return;
    }
    this.createWishlistShortcut(data);

    this.mainElement.classList.remove(this.classNames.notificationBadgeUpdate);
    setTimeout(() => { this.mainElement.classList.add(this.classNames.notificationBadgeUpdate) }, 10);
  }

  createCartShortcut() {
    this.setInitialBadgeCount();
  }

  createWishlistShortcut(data) {
    this.notificationBadge.textContent = data ? data : this.notificationBadge.dataset.initialCount;
    if (this.notificationBadge.dataset.initialCount > 0 || data > 0) {
      this.mainElement.classList.add(this.classNames.notificationBadgeActive);
      this.mainElement.classList.add(this.classNames.hasBadge);
    }
  }

  setInitialBadgeCount(data) {
    this.notificationBadge.textContent = data ? data : this.notificationBadge.dataset.initialCount;

    if (this.notificationBadge.dataset.initialCount > 0 || data > 0) {
      this.notificationBadge.classList.add(this.classNames.notificationBadgeActive);
      this.mainElement.classList.add(this.classNames.hasBadge);
    } else {
      this.notificationBadge.classList.remove(this.classNames.notificationBadgeActive);
      this.mainElement.classList.remove(this.classNames.hasBadge);
    }
  }
}

export default UserShortcut;
