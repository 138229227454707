import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "flag__boxSetLayer"
}
const _hoisted_3 = { class: "flag__boxSetLayer-content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 3 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["href", "innerHTML", "target"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_element = _resolveComponent("image-element")!
  const _component_info_popup = _resolveComponent("info-popup")!

  return (_ctx.flagData && _ctx.flagData.length > 0)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.lazyLoad ? 'lazyLoad' : 'ul'), {
        key: 0,
        tag: _ctx.lazyLoad ? 'ul' : null,
        class: "flags"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flagData, (flag, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass(["flag", [`flag--${flag.type}`]]),
              onClick: ($event: any) => (_ctx.clickHandler(flag, $event))
            }, [
              (flag.type === 'award' || flag.type === 'awardInSet')
                ? (_openBlock(), _createBlock(_component_image_element, {
                    key: 0,
                    src: [flag.data],
                    alt: ""
                  }, null, 8 /* PROPS */, ["src"]))
                : (flag.type === 'boxSetLayer')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_ctx.flags.boxSetLayer)
                        ? (_openBlock(), _createBlock(_component_info_popup, {
                            key: 0,
                            "show-close-icon": true,
                            position: "bottom-left"
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("span", {
                                  class: "flag__boxSetLayer-text",
                                  innerHTML: _ctx.$t('flags.boxSetLayer.text')
                                }, null, 8 /* PROPS */, _hoisted_4),
                                _createTextVNode(),
                                _createVNode(_component_image_element, {
                                  src: [_ctx.settings.flags.src.boxSetImage],
                                  alt: ""
                                }, null, 8 /* PROPS */, ["src"])
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('flags.boxSetLayer.info', [_ctx.flags.boxSetLayer.noOfBottlesForBox])), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : (flag.type === 'boxSetUrl')
                    ? (_openBlock(), _createBlock(_component_image_element, {
                        key: 2,
                        src: [_ctx.settings.flags.src.boxSetImage],
                        alt: ""
                      }, null, 8 /* PROPS */, ["src"]))
                    : (flag.type === 'quote')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _createElementVNode("span", {
                            class: "flag__quote",
                            innerHTML: flag.data[0]
                          }, null, 8 /* PROPS */, _hoisted_6),
                          _createTextVNode(),
                          _createElementVNode("span", {
                            class: "flag__author",
                            innerHTML: flag.data[1]
                          }, null, 8 /* PROPS */, _hoisted_7)
                        ]))
                      : (flag.type === 'takeAndPayBenefitText' || flag.type === 'takeAndPayBenefitAppliedItemsText')
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 4,
                            class: "flag__takeAndBenefit",
                            innerHTML: flag.data
                          }, null, 8 /* PROPS */, _hoisted_8))
                        : (flag.type === 'nachhaltigkeit')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                              (_ctx.enableLinks)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: _ctx.$t('flags.nachhaltigkeit.link'),
                                    class: "flag--link",
                                    innerHTML: flag.data,
                                    target: _ctx.getTarget(flag.type)
                                  }, null, 8 /* PROPS */, _hoisted_9))
                                : (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    innerHTML: flag.data
                                  }, null, 8 /* PROPS */, _hoisted_10))
                            ], 64 /* STABLE_FRAGMENT */))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 6,
                              innerHTML: flag.data
                            }, null, 8 /* PROPS */, _hoisted_11))
            ], 10 /* CLASS, PROPS */, _hoisted_1))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["tag"]))
    : _createCommentVNode("v-if", true)
}