<template>
  <div class="input">
    <label
      class="input__label"
      :class="{
        'input__label--hide-label': hideLabel && innerValue,
        'input__label--as-placeholder': (!focus && !innerValue) || (focus && hideLabel && !innerValue),
        'input__label--only-mobile': labelType === 'only-mobile',
        'input__label--required': required
      }"
      v-if="label"
    >
      {{ inputLabel }}
    </label>
    <div
      class="input__wrapper"
      :class="{
        'input__wrapper--hidden': type === 'hidden',
        'input__wrapper--textarea': type === 'textarea',
        'input__wrapper--focus': focus,
        'input__wrapper--error': !!error,
        'input__wrapper--valid': (!error && innerValue && !focus),
      }"
    >
      <textarea
        v-if="type === 'textarea'"
        class="input__field input__field--textarea"
        :type="type"
        :name="innerName"
        :value="innerValue"
        ref="inputField"
        :placeholder="inputPlaceholder"
        :disabled="disabled"
        @input="updateValue"
        @blur="blurHandler"
        @focus="focusHandler"
        @keyup.down="$emit('down')"
        @keyup.up="$emit('up')"
        @keydown.tab="$emit('tab', $event)"
        @keydown.enter="$emit('enter', $event)"
        :autocomplete="autocomplete"
        :autocorrect="autocomplete"
        :[inputAttribute]="inputAttribute"
      />
      <input
        v-else
        class="input__field"
        :type="type"
        :inputmode="inputmode"
        :name="innerName"
        :value="innerValue"
        ref="inputField"
        :placeholder="inputPlaceholder"
        :disabled="disabled"
        @input="updateValue"
        @blur="blurHandler"
        @focus="focusHandler"
        @keyup="$emit('keyup', $event)"
        @keyup.down="$emit('down')"
        @keyup.up="$emit('up')"
        @keydown="$emit('keydown', $event)"
        @keydown.tab="$emit('tab', $event)"
        @keydown.enter="$emit('enter', $event)"
        :autocomplete="autocomplete"
        :[inputAttribute]="inputAttribute"
      >
    </div>
    <transition name="input-error--animation">
      <span
        class="input-error"
        v-if="!!error"
        v-html="error"
      />
    </transition>
  </div>
</template>

<script lang="ts">
  import {Options, Prop, Watch, Vue} from 'vue-property-decorator';

  @Options({
    name: 'TextInput',
    emits: ['update:modelValue', 'input', 'blur', 'focus', 'down', 'up', 'tab', 'enter', 'keyup', 'keydown'],
  })
  export default class TextInput extends Vue {
    @Prop({default: ''})
    name: string;

    @Prop({default: ''})
    value: string;

    @Prop({default: ''})
    modelValue: string;

    @Prop({default: ''})
    label: string;

    @Prop({default: ''})
    labelType: string;

    @Prop({default: 'on'})
    autocomplete: string;

    @Prop({default: false})
    hideLabel: boolean;

    @Prop({default: false})
    disabled: boolean;

    @Prop({default: false})
    required: boolean;

    @Prop()
    inputmode: string;

    @Prop({
      default: 'text', validator: (val: string) => (
        ['url', 'text', 'password', 'email', 'search', 'hidden', 'textarea', 'number', 'tel'].indexOf(val) !== -1
      )
    })
    type: string;

    @Prop({required: false})
    error: string;

    @Prop({default: null})
    inputAttribute: string;

    @Prop({default: false})
    hideMandatoryAsterisk: boolean;

    focus: boolean = false;
    innerName: string = '';
    innerValue: string = '';

    get mandatoryAsterisk() {
      return this.hideMandatoryAsterisk ? '' : this.$t('form.mandatory_asterisk');
    }

    get inputPlaceholder(): string {
      if (this.hideLabel) {
        return this.label + (this.required ? this.mandatoryAsterisk : '');
      }
      return this.focus ? '' : this.label + (this.required ? this.mandatoryAsterisk : '');
    }

    get inputLabel(): string {
      return this.label + (this.required ? this.mandatoryAsterisk : '');
    }

    mounted() {
      this.innerName = this.name;
      this.innerValue = this.value || this.modelValue;
    }

    @Watch('value')
    @Watch('modelValue')
    onValueChanged(val: string) {
      this.innerValue = val;
    }

    updateValue(e) {
      this.innerValue = e.target.value;
      this.$emit('update:modelValue', this.innerValue);
      this.$emit('input', this.innerValue);
    }

    blurHandler() {
      this.focus = false;
      if (
        ['text', 'number', 'tel', 'email'].indexOf(this.type) >= 0 &&
        this.innerValue?.length > 0 &&
        this.innerValue !== this.innerValue.trim()
      ) {
        this.innerValue = this.innerValue.trim();
        this.$emit('update:modelValue', this.innerValue);
        this.$emit('input', this.innerValue);
      }
      this.$emit('blur');
    }

    focusHandler() {
      this.focus = true;
      this.$emit('focus');
    }

    $refs: Vue['$refs'] & {
      inputField: HTMLInputElement
    };

    focusInput() {
      this.focusHandler();
      this.$refs.inputField.focus();
    }
  }
</script>
